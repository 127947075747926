import React, { isValidElement } from "react";
import PropTypes from "prop-types";
import { isString } from "lodash";
import ReactMarkdown from "react-markdown";
import EventListener from "react-event-listener";

import { createPortal } from "react-dom";
import { Tooltip } from "./tooltip";

/**
 * Decorator component that can give a tooltip to anything inside of it.
 * It acts as a transparent pass-through and does not actually generate dom.
 * If interactive, it will remain open on hover and will no longer act transparent.
 */
class Tooltipify extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
      mounted: false,
    };

    this.childrenRef = React.createRef();
    this.tooltipRef = React.createRef();

    this.portalEl = document.createElement("div");
    this.el = null;
  }

  componentDidMount() {
    document.body.appendChild(this.portalEl);
    this.setState({ mounted: true });
  }

  componentDidUpdate() {
    this.calculateTooltipPosition();
  }

  componentWillUnmount() {
    document.body.removeChild(this.portalEl);
  }

  renderTooltip() {
    const { message, show, style, interactive } = this.props;

    let showTooltip = show === undefined ? this.state.show : show;

    if (message === undefined || message === null || message === "") {
      showTooltip = false;
    }

    /* This controls which types of markup we allow */
    const allowed = [
      // TODO: Uncomment when we upgrade react-markdown
      // 'text',
      "blockquote",
      "break",
      "code",
      "emphasis",
      "heading",
      "inlineCode",
      "link",
      "list",
      "listItem",
      "paragraph",
      "strong",
    ];

    const tooltip = (
      <Tooltip
        ref={this.tooltipRef}
        show={showTooltip}
        style={style}
        interactive={interactive}
        mouseEnter={this.handleMouseEnterTooltip.bind(this)}
        mouseLeave={this.handleMouseLeaveTooltip.bind(this)}
      >
        {(isString(message) && (
          <ReactMarkdown
            source={message.toString()}
            renderers={{
              link: (props) => (
                <a href={props.href} target="_blank" rel="noopener noreferrer">
                  {props.children}
                </a>
              ),
            }}
            allowedTypes={allowed}
            className=""
          />
        )) ||
          message}
      </Tooltip>
    );

    return createPortal(tooltip, this.portalEl);
  }

  calculateTooltipPosition() {
    const tooltipEl = this.tooltipRef.current;
    if (tooltipEl && this.el) {
      const { top, left } = this.calculatePosition(tooltipEl, this.el);
      tooltipEl.style.top = `${top}px`;
      tooltipEl.style.left = `${left}px`;
    }
  }

  calculatePosition(el, anchorEl) {
    const { offset, interactive } = this.props;

    const { top, bottom, left } = anchorEl.getBoundingClientRect();

    const { clientHeight, clientWidth } = el;

    const { width } = anchorEl.getBoundingClientRect();

    // Is there enough room above the target?
    const displayBottom = top - offset.top < clientHeight;

    const borderWidth = interactive ? 15 : 0;

    return {
      top: displayBottom
        ? bottom + offset.top - borderWidth
        : top - clientHeight - offset.top - borderWidth,
      left: left - clientWidth / 2 + width / 2 - borderWidth,
    };
  }

  handleMouseEnterTooltip() {
    this.setState({
      show: true,
    });
  }

  handleMouseLeaveTooltip() {
    this.setState({
      show: false,
    });
  }

  handleMouseEnter(e) {
    const { onMouseEnter, disabled, showOnEllipsis } = this.props;

    this.el = this.childrenRef.current || e.currentTarget;

    let shouldShow = true;

    // Checks if ellipsis is present by comparing width of text with width/height of element
    if (showOnEllipsis) {
      const overflow =
        this.el.clientWidth < this.el.scrollWidth ||
        this.el.clientHeight < this.el.scrollHeight;

      if (!overflow) {
        shouldShow = false;
      }
    }

    this.setState({
      show: shouldShow && !disabled,
    });

    onMouseEnter && onMouseEnter(e);
  }

  handleMouseLeave(e) {
    const { onMouseLeave } = this.props;

    this.setState({
      show: false,
    });

    onMouseLeave && onMouseLeave(e);
  }

  handleWindowMouseMove(e) {
    const { show } = this.props;

    if (
      // Only do this if show is not forced
      show !== undefined ||
      !this.el ||
      !this.tooltipRef.current ||
      // this is an IE specific condition because it does not support matches
      // nor the :hover selector even with a polyfill
      !Element.prototype.matches ||
      // the next two conditions will detect if the mouse is over the tooltip
      this.el.matches(":hover") ||
      this.tooltipRef.current.matches(":hover")
    ) {
      return;
    }

    this.handleMouseLeave(e);
  }

  handleWindowScroll(e) {
    const { show } = this.props;

    // Only do this if show is not forced
    if (show === undefined) {
      this.handleMouseLeave(e);
    }
  }

  render() {
    const { children, style, className } = this.props;
    const { show, mounted } = this.state;

    const handlers = {
      onMouseEnter: this.handleMouseEnter.bind(this),
      onMouseLeave: this.handleMouseLeave.bind(this),
    };

    let contents = null;

    if (!children) {
      return null;
    }

    // Disabled elements such as inputs, buttons, etc
    // WILL NOT fire mouseLeave and other oddities
    // This is a workaround for this, see react issue #4251
    if (children.props && children.props.disabled) {
      contents = (
        <span className={className} style={style} {...handlers}>
          <span ref={this.childrenRef} style={{ pointerEvents: "none" }}>
            {children}
          </span>
        </span>
      );
    } else if (isValidElement(children)) {
      contents = React.cloneElement(children, handlers);
    } else if (children) {
      contents = (
        <span className={className} style={style} {...handlers}>
          {children}
        </span>
      );
    }

    return (
      <>
        {contents}
        {mounted && this.renderTooltip()}
        {show && (
          <EventListener
            target="window"
            onMouseMove={this.handleWindowMouseMove.bind(this)}
            onScroll={this.handleWindowScroll.bind(this)}
          />
        )}
      </>
    );
  }
}

Tooltipify.propTypes = {
  message: PropTypes.node,
  // Displays tooltip on element if ellipsis is present
  showOnEllipsis: PropTypes.bool,
};

Tooltipify.defaultProps = {
  offset: {
    top: 8,
  },
};

export default Tooltipify;
