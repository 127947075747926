import React, { CSSProperties } from "react";
import WarningIcon from "@deprecated/material-ui/svg-icons/alert/warning";

/**
 * Stylized error component for use inside forms.
 */
const FormError: React.FC<
  React.PropsWithChildren & {
    name?: string;
    style?: CSSProperties;
    hideIcon?: boolean;
  }
> = (props) => {
  const { children, name, style, hideIcon } = props;

  if (!children) {
    return null;
  }

  const dynamicStyle = {
    ...style,
    display: "flex",
    alignItems: "center",
    marginLeft: hideIcon ? "0px" : style?.marginLeft || "-8px",
  };

  return (
    <div
      className="sde-form-error"
      data-cy="error-message"
      data-error={name}
      style={dynamicStyle}
    >
      {!hideIcon && <WarningIcon />}
      {children}
    </div>
  );
};

export default FormError;
